<template>
  <div class="secure-settings">
    <h3 class="title">Security Settings</h3>
    <safety-item v-for="item in safetyList" :key="item.title" v-bind="item"></safety-item>
  </div>
</template>

<script>
import SafetyItem from './components/safetyItem'
export default {
  name: 'Secure-Setting',
  components: {
    SafetyItem
  },
  data() {
    const safetyList = [
      {
        title: 'Account / Password',
        desc: 'Current password strength: Strong'
      },
      {
        title: 'Security cell phone',
        desc: 'Mobile phone attached: 180****8888'
      },
      {
        title: 'Security problem',
        desc: 'No security issue is set. Security issue can effectively protect account security'
      },
      {
        title: 'Spare mailbox',
        desc: 'The email address exam****@example.com has been bound'
      }
    ]
    return {
      safetyList
    }
  }
}
</script>

<style scoped lang="less">
.title {
  font-size: 20px;
}
.safety-item:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}
</style>
